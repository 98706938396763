import React from "react";
import { posts } from "@/app/data/blogPostData";
import { sortPostsByDate } from "@/app/shared/utils/utils";
import { getFeaturedPosts } from "@/app/shared/utils/getFeaturedPosts";
import PostsList from "../PostsList/PostsList";
import ForwardArrowLink from "@/app/shared/components/ForwardArrowLink/ForwardArrowLink";

const WritingSection: React.FC = () => {
  const allPosts = sortPostsByDate(posts);
  const featuredPosts = getFeaturedPosts();

  if (posts.length < 1) return null;

  return (
    <section
      id="writing"
      className="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24"
      aria-label="Blog posts"
    >
      <div className="sticky top-0 z-20 -mx-6 mb-4 w-screen bg-slate-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
        <h2 className="text-sm font-bold uppercase tracking-widest text-slate-200 lg:sr-only">
          Writing
        </h2>
      </div>
      <div>
        {featuredPosts.length > 0 && <PostsList posts={featuredPosts} />}
        {allPosts.length > 2 && (
          <div className="mt-12">
            <ForwardArrowLink
            href="/blog"
            text={["View All", "Posts"]}
            label="View All Posts"
          />
          </div>
        )}
      </div>
    </section>
  );
};

export default WritingSection;
