import React from "react";
import { FaMobile, FaEnvelope } from "react-icons/fa";
import SocialMedia from "../SocialMedia/SocialMedia";

const Contact: React.FC = () => {
    return (
        <div className="mt-8">
          <div className="flex items-center mt-4 small_laptop_hidden">
            <FaMobile className="inline-block mr-2" />
            <span className="inline-block">+44 0777 793 8405</span>
          </div>
          <div className="flex items-center mt-2 small_laptop_hidden">
            <FaEnvelope className="inline-block mr-2" />
            <span className="inline-block"><a className="hover:text-slate-200" href="mailto:martinbeck.and@gmail.com">martinbeck.and@gmail.com</a></span>
          </div>
          <SocialMedia />
        </div>
    );
};

export default Contact;