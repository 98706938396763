import React from "react";
import { IProject } from "../../interfaces";
import { generateStringId } from "../../utils/utils";

interface IComponentProps {
  skills: IProject["skills"];
}

const SKills: React.FC<IComponentProps> = ({ skills }) => {
  return (
    <ul className="mt-2 flex flex-wrap" aria-label="Technologies Used">
      {skills.map((skill) => (
        <li className="mr-1.5 mt-2" key={generateStringId()}>
          <div className="flex items-center rounded-full bg-blue-400/10 px-3 py-1 text-xs font-medium leading-5 text-blue-300">
            {skill}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default SKills;