import React from "react";
import AboutSection from "../AboutSection/AboutSection";
import ExperienceSection from "../ExperienceSection/ExperienceSection";
import ProjectsSection from "../ProjectsSection/ProjectsSection";
import Footer from "../Footer/Footer";
import WritingSection from "../WritingSection/WritingSection";

interface IMainSectionProps {
  aboutRef: React.RefObject<HTMLElement>;
  experienceRef: React.RefObject<HTMLElement>;
  projectsRef: React.RefObject<HTMLElement>;
}

const MainSection: React.FC<IMainSectionProps> = ({
  aboutRef,
  experienceRef,
  projectsRef,
}) => {
  return (
    <main id="content" className="pt-24 lg:w-1/2 lg:py-24">
      <AboutSection reference={aboutRef} />
      <ExperienceSection reference={experienceRef} />
      <ProjectsSection reference={projectsRef} />
      <WritingSection />
      <Footer />
    </main>
  );
};

export default MainSection;
