"use client";

import React, { useEffect } from "react";
import HeaderSection from "../HeaderSection/HeaderSection";
import MainSection from "../MainSection/MainSection";
import TopSection from "../../shared/components/TopSection/TopSection";
import PageWrapper from "../PageWrapper/PageWrapper";

interface IContentSectionProps {
  aboutRef: React.RefObject<HTMLDivElement>;
  experienceRef: React.RefObject<HTMLDivElement>;
  projectsRef: React.RefObject<HTMLDivElement>;
  activeSection: React.RefObject<HTMLDivElement>;
  setActiveSection: React.Dispatch<
    React.SetStateAction<React.RefObject<HTMLDivElement>>
  >;
  handleScrollToSection: (ref: React.RefObject<HTMLDivElement>) => void;
}

const ContentSection: React.FC<IContentSectionProps> = ({
  aboutRef,
  experienceRef,
  projectsRef,
  activeSection,
  setActiveSection,
  handleScrollToSection,
}) => {
  useEffect(() => {
    const viewportHeight = window.innerHeight;

    const thresholdValue = viewportHeight <= 710 ? 0.2 : 0.45;

    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: thresholdValue,
    };

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target.id === "about") {
            setActiveSection(aboutRef);
          }

          if (entry.target.id === "experience") {
            setActiveSection(experienceRef);
          }

          if (entry.target.id === "projects") {
            setActiveSection(projectsRef);
          }
        }
      });
    };

    const observer = new IntersectionObserver(
      handleIntersection,
      observerOptions
    );

    const aboutRefCurrent = aboutRef.current;
    const experienceRefCurrent = experienceRef.current;
    const projectsRefCurrent = projectsRef.current;

    if (aboutRefCurrent) {
      observer.observe(aboutRefCurrent);
    }
    if (experienceRefCurrent) {
      observer.observe(experienceRefCurrent);
    }
    if (projectsRefCurrent) {
      observer.observe(projectsRefCurrent);
    }

    return () => {
      if (aboutRefCurrent) {
        observer.unobserve(aboutRefCurrent);
      }
      if (experienceRefCurrent) {
        observer.unobserve(experienceRefCurrent);
      }
      if (projectsRefCurrent) {
        observer.unobserve(projectsRefCurrent);
      }
    };
  }, [aboutRef, experienceRef, projectsRef, setActiveSection]);

  return (
      <PageWrapper>
        <TopSection />
        <div className="mx-auto min-h-screen max-w-screen-xl px-6 py-12 md:px-12 md:py-20 lg:px-24 lg:py-0">
          <div className="lg:flex lg:justify-between lg:gap-4">
            <HeaderSection
              aboutRef={aboutRef}
              experienceRef={experienceRef}
              projectsRef={projectsRef}
              scrollToSection={handleScrollToSection}
              activeSection={activeSection}
              setActiveSection={setActiveSection}
            />
            <MainSection
              aboutRef={aboutRef}
              experienceRef={experienceRef}
              projectsRef={projectsRef}
            />
          </div>
        </div>
      </PageWrapper>
  );
};

export default ContentSection;
