import React from "react";
import { ILinkComponentProps } from "../../interfaces";
import { IoArrowForwardSharp } from "react-icons/io5";
import Link from "next/link";

const ForwardArrowLink: React.FC<ILinkComponentProps> = ({
  href,
  text,
  label,
  target,
}) => {
  return (
    <Link
      className="inline-flex items-center leading-tight font-semibold text-slate-200 group cursor-pointer"
      href={href}
      target={target}
      aria-label={label}
    >
      <span>
        <span className="border-b border-transparent pb-px transition group-hover:border-blue-300 motion-reduce:transition-none">
          {text[0]}&nbsp;
        </span>
        <span className="whitespace-nowrap">
          {text.map(
            (t, idx) =>
              idx > 0 && (
                <React.Fragment key={`${text}-${idx}`}>
                  <span className="border-b border-transparent pb-px transition group-hover:border-blue-300 motion-reduce:transition-none">
                    {t}
                  </span>
                </React.Fragment>
              )
          )}
          <IoArrowForwardSharp className="ml-1 inline-block h-4 w-4 shrink-0 -translate-y-px transition-transform group-hover:translate-x-2 group-focus-visible:translate-x-2 motion-reduce:transition-none" />
        </span>
      </span>
    </Link>
  );
};

export default ForwardArrowLink;
