import { IFeaturedPosts } from "../shared/interfaces";
import { generateStringId } from "../shared/utils/utils";

/*!
 * IMPORTANT:
 * If any changes are made to the slug,
 * then you need to change it the corresponding folder to the new slug as well.
 * Furthermore, you need to change the slug passed down to the <BlogPageWrapper> component.
!*/

export const posts: ReadonlyArray<IFeaturedPosts> = [
  {
    id: generateStringId(),
    title: "Navigating Greek Work Culture: Opportunities and Challenges",
    excerpt:
      `Exploring the nuances of Greek work culture, from its warmth and hospitality 
      to the challenges of high work rates and the evolving digital landscape.`.trim(),
    slug: "navigating-greek-work-culture",
    thumbnailSrc: "/images/posts/navigating-greek-work-culture/thumbnail.webp",
    bannerSrc: "/images/posts/navigating-greek-work-culture/banner.webp",
    date: new Date("2020-03-13"),
    categories: ["culture", "work culture", "freelance"],
    author: {
      name: "Martin Beck Andersen",
      imageSrc: "/images/posts/author/martin.webp",
    },
  },
  {
    id: generateStringId(),
    title: "The Dynamic Duo of Development: Exploring Pair Programming",
    excerpt: `Explore the brilliance of Pair Programming and its benefits. 
      Uncover relationship, teaching dynamics, and extensive coding technique advantages.`.trim(),
    slug: "pair-programming-benefits",
    thumbnailSrc: "/images/posts/pair-programming-benefits/thumbnail.webp",
    bannerSrc: "/images/posts/pair-programming-benefits/banner.webp",
    date: new Date("2021-05-16"),
    categories: ["tech", "software development", "collaboration"],
    author: {
      name: "Martin Beck Andersen",
      imageSrc: "/images/posts/author/martin.webp",
    },
  },
  {
    id: generateStringId(),
    title:
      "Unlocking Success in Software Development: The Power of Communication",
    excerpt:
      `Exploring the indispensable role of communication and the art of being heard 
    in the intricate landscape of software development.`.trim(),
    slug: "software-development-communication",
    thumbnailSrc:
      "/images/posts/software-development-communication/thumbnail.webp",
    bannerSrc: "/images/posts/software-development-communication/banner.webp",
    date: new Date("2023-11-14"),
    categories: ["tech", "software-development", "communication"],
    author: {
      name: "Martin Beck Andersen",
      imageSrc: "/images/posts/author/martin.webp",
    },
  },
  {
    id: generateStringId(),
    title: "So You Made It to Lead Engineer. Now What?",
    excerpt:
      `Insights and personal reflections on transitioning into a lead engineering role in the tech industry.`.trim(),
    slug: "lead-engineer-transition",
    thumbnailSrc:
      "/images/posts/lead-engineer-transition/lead-engineer-transition.webp",
    bannerSrc:
      "/images/posts/lead-engineer-transition/lead-engineer-transition-banner.webp",
    date: new Date("2023-12-05"),
    categories: ["tech", "engineering", "leadership"],
    author: {
      name: "Martin Beck Andersen",
      imageSrc: "/images/posts/author/martin.webp",
    },
  },
  {
    id: generateStringId(),
    title:
      "Navigating the Tech Landscape: The Power of Foundation in Software Engineering",
    excerpt:
      `Insights into the significance of foundational knowledge and adaptability in the journey of a software engineer 
    amidst evolving frameworks in the tech industry.`.trim(),
    slug: "navigating-tech-landscape",
    thumbnailSrc: "/images/posts/navigating-tech-landscape/thumbnail.webp",
    bannerSrc: "/images/posts/navigating-tech-landscape/banner.webp",
    date: new Date("2023-12-13"),
    categories: ["tech", "software-engineering", "adaptability"],
    author: {
      name: "Martin Beck Andersen",
      imageSrc: "/images/posts/author/martin.webp",
    },
  },
];
