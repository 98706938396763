"use client";

import { useRef, useState } from "react";
import ContentSection from "../ContentSection/ContentSection";

const CVPage: React.FC = () => {
  const aboutRef = useRef<HTMLDivElement>(null);
  const experienceRef = useRef<HTMLDivElement>(null);
  const projectsRef = useRef<HTMLDivElement>(null);

  const [activeSection, setActiveSection] =
    useState<React.RefObject<HTMLDivElement>>(aboutRef);

  const handleScrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="min-h-screen">
      <ContentSection
        aboutRef={aboutRef}
        experienceRef={experienceRef}
        projectsRef={projectsRef}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        handleScrollToSection={handleScrollToSection}
      />
    </div>
  );
};

export default CVPage;
