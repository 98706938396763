import React from "react";
import { IFeaturedPosts } from "@/app/shared/interfaces";
import Image from "next/image";
import Link from "next/link";
import { MdOutlineArrowOutward } from "react-icons/md";

interface IComponentProps {
  posts: IFeaturedPosts[];
}

const PostsList: React.FC<IComponentProps> = ({ posts }) => {
  return (
    <ul className="group/list">
      {posts.map((post) => (
        <li className="mb-12" key={post.id}>
          <div className="group relative grid grid-cols-8 gap-4 transition-all sm:items-center sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
            <div className="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg" />
            <Image
              alt={post.title}
              src={post.thumbnailSrc}
              loading="lazy"
              width={200}
              height={48}
              decoding="async"
              className="z-10 col-span-2 rounded border-2 border-slate-200/10 transition group-hover:border-slate-200/30 sm:col-span-2"
              style={{ color: "transparent" }}
            />
            <div className="z-10 col-span-6">
              <p className="-mt-1 text-sm font-semibold leading-6">
                {post.date.getFullYear()}
              </p>
              <h3 className="-mt-1">
                <Link
                  href={`blog/${post.slug}`}
                  rel="canonical"
                  aria-label={post.title}
                  className="inline-flex items-baseline font-medium leading-tight text-slate-200 hover:text-blue-300 focus-visible:text-blue-300  group/link text-base"
                >
                  <span className="absolute -inset-x-4 -inset-y-2.5 hidden rounded md:-inset-x-6 md:-inset-y-4 lg:block" />
                  <span className="inline-block">
                    {post.title}
                    <MdOutlineArrowOutward className="inline-block h-4 w-4 shrink-0 transition-transform group-hover/link:-translate-y-1 group-hover/link:translate-x-1 group-focus-visible/link:-translate-y-1 group-focus-visible/link:translate-x-1 motion-reduce:transition-none ml-1 translate-y-px" />
                  </span>
                </Link>
              </h3>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default PostsList;
