/* eslint-disable @next/next/no-img-element */
import { IProject, IProjectsListProps } from "@/app/shared/interfaces";
import Link from "next/link";
import Image from "next/image";
import { MdOutlineArrowOutward } from "react-icons/md";
import React from "react";
import SKills from "@/app/shared/components/Skills/Skills";

const ProjectsList: React.FC<IProjectsListProps> = ({ projects }) => {
  return (
    <ol className="group/list">
      {projects.map((project) => (
        <li className="mb-12" key={project.id}>
          <div className="group relative grid gap-4 pb-1 transition-all sm:grid-cols-8 sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
            <div className="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg" />
            <div className="z-10 sm:order-2 sm:col-span-6">
              <h3>
                <Link
                  className={`inline-flex items-baseline font-medium leading-tight text-slate-200 hover:text-blue-300 focus-visible:text-blue-300  group/link text-base ${
                    !project.link && "pointer-events-none cursor-auto"
                  }`}
                  href={project.link || ""}
                  target="_blank"
                  aria-label={project.name}
                >
                  <span className="absolute -inset-x-4 -inset-y-2.5 hidden rounded md:-inset-x-6 md:-inset-y-4 lg:block" />
                  <span>{project.name}</span>
                  {project.link && (
                    <span className="inline-block">
                      <MdOutlineArrowOutward className="inline-block h-4 w-4 shrink-0 transition-transform group-hover/link:-translate-y-1 group-hover/link:translate-x-1 group-focus-visible/link:-translate-y-1 group-focus-visible/link:translate-x-1 motion-reduce:transition-none ml-1 translate-y-px" />
                    </span>
                  )}
                </Link>
              </h3>
              <p className="mt-2 text-sm leading-normal">
                {project.description}
              </p>
              <SKills skills={project.skills} />
            </div>
            {project.imageSrc && (
              <Image
                alt={project.name}
                src={project.imageSrc}
                loading="lazy"
                width={200}
                height={48}
                decoding="async"
                className="rounded border-2 border-slate-200/10 transition group-hover:border-slate-200/30 sm:order-1 sm:col-span-2 sm:translate-y-1"
                style={{ color: "transparent" }}
              />
            )}
          </div>
        </li>
      ))}
    </ol>
  );
};

export default ProjectsList;
