/* eslint-disable @next/next/no-img-element */
import React from "react";
import { ISectionProps } from "@/app/shared/interfaces";
import { getFeaturedProjects } from "@/app/shared/utils/getFeaturedProjects";
import ProjectsList from "../ProjectsList/ProjectsList";
import ForwardArrowLink from "@/app/shared/components/ForwardArrowLink/ForwardArrowLink";

const ProjectsSection: React.FC<ISectionProps> = ({ reference }) => {
  const featuredProjects = getFeaturedProjects();

  return (
    <section
      ref={reference}
      id="projects"
      className="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24"
      aria-label="Selected Projects"
    >
      <div className="sticky top-0 z-20 -mx-6 mb-4 w-screen bg-slate-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
        <h2 className="text-sm font-bold uppercase tracking-widest text-slate-200 lg:sr-only">
          Projects
        </h2>
      </div>
      <div>
        <ProjectsList projects={featuredProjects} />
        <div className="mt-12">
          <ForwardArrowLink
            href="/archive"
            text={["View Full", "Project Archive"]}
            label="View Full Project Archive"
          />
        </div>
      </div>
    </section>
  );
};

export default ProjectsSection;
