"use client";

import React, { useCallback } from "react";
import { IScrollProps } from "@/app/shared/interfaces";
import Link from "next/link";
import Nav from "../Nav/Nav";
import Contact from "../Contact/Contact";

const HeaderSection: React.FC<IScrollProps> = ({
  aboutRef,
  experienceRef,
  projectsRef,
  activeSection,
  scrollToSection,
  setActiveSection,
}) => {
  const handleNavClick = useCallback(
    (ref: React.RefObject<HTMLDivElement>) => {
      scrollToSection(ref);
      setActiveSection(ref);
    },
    [scrollToSection, setActiveSection]
  );

  return (
    <header className="lg:sticky lg:top-0 lg:flex lg:max-h-screen lg:w-1/2 lg:flex-col lg:justify-between lg:py-24">
      <div>
        <h1 className="text-4xl font-bold tracking-tight text-slate-200 sm:text-5xl">
          <Link className="cursor-pointer" href="/">
            Martin Beck Andersen
          </Link>
        </h1>
        <h2 className="mt-3 text-lg font-medium tracking-tight text-slate-200 sm:text-xl">
          Experienced Front-End Engineer &amp; Lead
        </h2>
        <p className="mt-4 max-w-xs leading-normal">
          I build exceptional and accessible digital experiences for the web.
        </p>
        <Contact />
        <Nav
          aboutRef={aboutRef}
          experienceRef={experienceRef}
          projectsRef={projectsRef}
          activeSection={activeSection}
          handleNavClick={handleNavClick}
        />
      </div>
    </header>
  );
};

export default HeaderSection;
