import { IExperience } from "../shared/interfaces";
import { generateStringId } from "../shared/utils/utils";

export const experience: ReadonlyArray<IExperience> = [
  {
    id: generateStringId(),
    jobTitle: "Lead Engineer",
    companyName: "i4energy",
    description: `Working closely together with the product team, architect and
    the software team to to build a high-end, holistic platform by
    the name ACROSS™ for the Energy sector. Notable clients
    include the Public Power Company of Greece (PPC) and their
    subsidiary, PPC Renewables. Evaluation and analytical inputs
    of tender offers also occurs from time to time. Coaching of
    team members. i4energy is the software company subsidiary of
    MAS S.A.`.trim(),
    startYear: 2021,
    endYear: undefined,
    skills: ["React", "TypeScript", "HTML5", "CSS3", "JSS"],
    link: "https://i4.energy/",
  },
  {
    id: generateStringId(),
    jobTitle: "Freelancer",
    companyName: undefined,
    description: `As a freelance Multimedia Designer, I offer a full 360
    solution, including digital branding and marketing, as well as
    design, either within the full development cycle, or
    partially, depending on the client's needs. Most of the
    work consists of web design and web development for various
    clients in all industries.`.trim(),
    startYear: 2016,
    endYear: undefined,
    skills: [
      "WordPress",
      "PHP",
      "JavaScript",
      "HTML5",
      "CSS3",
      "SEO",
      "Illustrator",
      "Photoshop",
    ],
    link: undefined,
  },
  {
    id: generateStringId(),
    jobTitle: "Front-End Engineer",
    companyName: "MAS S.A",
    description: `Working on building a high-end, holistic platform by the name
    ACROSS™ for the Energy sector. Notable clients include
    the Public Power Company of Greece (PPC) and their subsidiary,
    PPC Renewables. This project was later moved under a
    subsidiary called i4energy.`.trim(),
    startYear: 2021,
    endYear: 2021,
    skills: ["React", "TypeScript", "HTML5", "CSS3", "JSS"],
    link: "https://www.maseurope.com/",
  },
  {
    id: generateStringId(),
    jobTitle: "Full-Stack Engineer",
    companyName: "SLEED",
    description: `Delivering high-quality, robust production code for a diverse
    array of projects for various clients; including, Spitishop,
    e-professor, Thanopoulos and many more.`.trim(),
    startYear: 2020,
    endYear: 2021,
    skills: [
      "Prestashop",
      "WordPress",
      "JavaScript",
      "PHP",
      "HTML5",
      "CSS3",
      "SASS",
      "LESS",
      "MySQL",
      "SEO",
    ],
    link: "https://www.sleed.com/",
  },
  {
    id: generateStringId(),
    jobTitle: "IT Designer",
    companyName: "ST-Consult ApS",
    description: `Development of a web based portal for consultants and
    businesses. Matching the two with each other based on various
    criteria. The portal includes management of candidates,
    businesses, projects, financial aspects and much more.`.trim(),
    startYear: 2017,
    endYear: 2018,
    skills: [
      "WordPress",
      "JavaScript",
      "PHP",
      "MySQL",
      "HTML5",
      "CSS3",
      "Bootstrap",
    ],
    link: "https://www.st-consult.dk/",
  },
  {
    id: generateStringId(),
    jobTitle: "Tutor",
    companyName: "Business Academy SouthWest",
    description: `A month long tutoring of Multimedia Design students in HTML,
    CSS and SEO.`.trim(),
    startYear: 2017,
    endYear: 2017,
    skills: ["HTML5", "CSS3", "SEO"],
    link: "https://www.easv.dk/en/",
  },
  {
    id: generateStringId(),
    jobTitle: "Intern",
    companyName: "Haug-IT ApS",
    description: `A 3 month long internship in a company, residing in a science
    park in Sønderborg, Denmark. The work consisted
    primarily of web analysis, web development, design, images and
    video.`.trim(),
    startYear: 2016,
    endYear: 2016,
    skills: [
      "HTML5",
      "CSS3",
      "JavaScript",
      "WordPress",
      "PHP",
      "SEO",
      "Photoshop",
      "Illustrator",
      "Premier Pro",
      "After Effects",
    ],
    link: "https://www.haug-it.dk/en/",
  },
];
