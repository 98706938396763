import Link from "next/link";
import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="max-w-md pb-16 text-sm text-slate-500 sm:pb-0">
      <p>
        Designed in{" "}
        <Link
          className="font-medium text-slate-400 hover:text-blue-300 focus-visible:text-blue-300"
          href="https://www.adobe.com/products/illustrator.html"
          target="_blank"
          rel="noreferrer"
        >
          Adobe Illustrator
        </Link>{" "}
        and Coded in{" "}
        <Link
          className="font-medium text-slate-400 hover:text-blue-300 focus-visible:text-blue-300"
          href="https://code.visualstudio.com/"
          target="_blank"
          rel="noreferrer"
        >
          Visual Studio Code
        </Link>{" "}
        by yours truly. Built with{" "}
        <Link
          className="font-medium text-slate-400 hover:text-blue-300 focus-visible:text-blue-300"
          href="https://nextjs.org/"
          target="_blank"
          rel="noreferrer"
        >
          Next.js
        </Link>{" "}
        and{" "}
        <Link
          className="font-medium text-slate-400 hover:text-blue-300 focus-visible:text-blue-300"
          href="https://tailwindcss.com/"
          target="_blank"
          rel="noreferrer"
        >
          Tailwind CSS
        </Link>
        , deployed with{" "}
        <Link
          className="font-medium text-slate-400 hover:text-blue-300 focus-visible:text-blue-300"
          href="https://vercel.com/dashboard"
          target="_blank"
          rel="noreferrer"
        >
          Vercel
        </Link>
        . All text is set in the{" "}
        <Link
          className="font-medium text-slate-400 hover:text-blue-300 focus-visible:text-blue-300"
          href="https://rsms.me/inter/"
          target="_blank"
          rel="noreferrer"
        >
          Inter
        </Link>{" "}
        typeface.
      </p>
    </footer>
  );
};

export default Footer;
