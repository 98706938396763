import Link from "next/link";
import React from "react";
import {
  FaGithub,
  FaLinkedin,
  FaInstagram,
  FaStackOverflow,
  FaTwitter
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6"

interface IComponentProps {
  text?: string;
}

const SocialMedia: React.FC<IComponentProps> = ({ text }) => {
  return (
    <>
      <ul className="ml-1 mt-8 md:mb-8 flex items-center">
        {text && (
          <li className="mr-5 text-xs uppercase">
            <h3>{text}</h3>
          </li>
        )}
        <li className="mr-5 text-xs">
          <Link
            className="block hover:text-slate-200"
            href="https://www.linkedin.com/in/martin-beck-andersen-b57957123/?locale=en_US"
            target="_blank"
          >
            <span className="sr-only">LinkedIn</span>
            <FaLinkedin size={24} />
          </Link>
        </li>
        <li className="mr-5 text-xs">
          <Link
            className="block hover:text-slate-200"
            href="https://stackoverflow.com/users/7535014/martin"
            target="_blank"
          >
            <span className="sr-only">Stack Overflow</span>
            <FaStackOverflow size={24} />
          </Link>
        </li>
        <li className="mr-5 text-xs">
          <Link
            className="block hover:text-slate-200"
            href="https://github.com/In7elligence"
            target="_blank"
          >
            <span className="sr-only">GitHub</span>
            <FaGithub size={24} />
          </Link>
        </li>
        <li className="mr-5 text-xs">
          <Link
            className="block hover:text-slate-200"
            href="https://www.instagram.com/mr.martinxyz"
            target="_blank"
          >
            <span className="sr-only">Instagram</span>
            <FaInstagram size={24} />
          </Link>
        </li>
        <li className="mr-5 text-xs">
          <Link
            className="block hover:text-slate-200"
            href="https://twitter.com/martindevxyz"
            target="_blank"
          >
            <span className="sr-only">Twitter</span>
            <FaXTwitter size={24} />
          </Link>
        </li>
      </ul>
    </>
  );
};

export default SocialMedia;
