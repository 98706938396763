import React from "react";
import { ISectionProps } from "@/app/shared/interfaces";
import Link from "next/link";

const AboutSection: React.FC<ISectionProps> = ({ reference }) => {
  return (
    <section
      ref={reference}
      id="about"
      className="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24"
      aria-label="About Me"
    >
      <div className="sticky top-0 z-20 -mx-6 mb-4 w-screen bg-slate-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
        <h2 className="text-sm font-bold uppercase tracking-widest text-slate-200 lg:sr-only">
          About
        </h2>
      </div>
      <div>
        <p className="mb-4">
          Back in 2013, I discovered Computer Science and Multimedia Design and
          dove head-first into coding and web development. Fast-forward to
          today, and I&apos;ve had the privilege of building a{" "}
          <Link
            className="font-medium text-slate-200 hover:text-blue-300 focus-visible:text-blue-300"
            href="https://i4.energy/across/"
            target="_blank"
            rel="noreferrer"
          >
            revolutionary platform{" "}
          </Link>
          in the energy sector, working for a{" "}
          <Link
            className="font-medium text-slate-200 hover:text-blue-300 focus-visible:text-blue-300"
            href="https://www.sleed.com/"
            target="_blank"
            rel="noreferrer"
          >
            web agency
          </Link>
          , and an{" "}
          <Link
            className="font-medium text-slate-200 hover:text-blue-300 focus-visible:text-blue-300"
            href="https://www.maseurope.com/"
            target="_blank"
            rel="noreferrer"
          >
            international firm
          </Link>
          .
        </p>
        <p className="mb-4">
          My main focus these days is building and maintaining the{" "}
          <Link
            className="font-medium text-slate-200 hover:text-blue-300 focus-visible:text-blue-300"
            href="https://i4.energy/across/"
            target="_blank"
            rel="noreferrer"
          >
            ACROSS&trade; platform
          </Link>
          , as well as leading and coaching the software team at{" "}
          <Link
            className="font-medium text-slate-200 hover:text-blue-300 focus-visible:text-blue-300"
            href="https://i4.energy/"
            target="_blank"
            rel="noreferrer"
          >
            i4energy
          </Link>
          . In my free time, I&apos;ve helped people on{" "}
          <Link
            className="font-medium text-slate-200 hover:text-blue-300 focus-visible:text-blue-300"
            href="https://stackoverflow.com/users/7535014/martin"
            target="_blank"
            rel="noreferrer"
          >
            Stack Overflow
          </Link>
          , as well as experimented with integrating AI in a{" "}
          <Link
            className="font-medium text-slate-200 hover:text-blue-300 focus-visible:text-blue-300"
            href="https://ketofitguru.vercel.app/"
            target="_blank"
            rel="noreferrer"
          >
            website
          </Link>{" "}
          for the product and web development, and social media.
        </p>
        <p className="mb-4">
          I recently moved to Glasgow in the UK, so when I&apos;m not at the computer, 
          I&apos;m either exploring Glasgow, enjoying music,
          or watching{" "}
          <Link
            className="group/movies inline-flex lg:cursor-[url('/images/mouseover/interstellar.png'),_pointer] font-medium text-slate-200"
            href="https://www.imdb.com/title/tt0816692/"
            target="_blank"
            rel="noreferrer"
          >
            <span className="group-hover/movies:text-blue-300 transition duration-75 group-hover/movies:-translate-y-px delay-[50ms]">
              m
            </span>
            <span className="group-hover/movies:text-blue-300 transition duration-75 group-hover/movies:-translate-y-px delay-[75ms]">
              o
            </span>
            <span className="group-hover/movies:text-blue-300 transition duration-75 group-hover/movies:-translate-y-px delay-[100ms]">
              v
            </span>
            <span className="group-hover/movies:text-blue-300 transition duration-75 group-hover/movies:-translate-y-px delay-[125ms]">
              i
            </span>
            <span className="group-hover/movies:text-blue-300 transition duration-75 group-hover/movies:-translate-y-px delay-[150ms]">
              e
            </span>
            <span className="group-hover/movies:text-blue-300 transition duration-75 group-hover/movies:-translate-y-px delay-[175ms]">
              s
            </span>
          </Link>{" "}
          and{" "}
          <Link
            className="group/shows inline-flex lg:cursor-[url('/images/mouseover/got.png'),_pointer] font-medium text-slate-200 hover:text-blue-300 focus-visible:text-blue-300"
            href="https://m.imdb.com/title/tt0944947/?ref_=chttvtp_t_13"
            target="_blank"
            rel="noreferrer"
          >
            <span className="group-hover/shows:text-blue-300 transition duration-75 group-hover/shows:-translate-y-px delay-[50ms]">
              s
            </span>
            <span className="group-hover/shows:text-blue-300 transition duration-75 group-hover/shows:-translate-y-px delay-[75ms]">
              h
            </span>
            <span className="group-hover/shows:text-blue-300 transition duration-75 group-hover/shows:-translate-y-px delay-[100ms]">
              o
            </span>
            <span className="group-hover/shows:text-blue-300 transition duration-75 group-hover/shows:-translate-y-px delay-[125ms]">
              w
            </span>
            <span className="group-hover/shows:text-blue-300 transition duration-75 group-hover/shows:-translate-y-px delay-[150ms]">
              s
            </span>
          </Link>
          .
        </p>
      </div>
    </section>
  );
};

export default AboutSection;
