import { IProject } from "../interfaces";
import { projects } from "@/app/data/projectData";

export const getFeaturedProjects = (): IProject[] => {
  const allProjects = [...projects];
  const featuredProjects = allProjects
    .filter((project) => project.featured)
    .sort((a, b) => b.year - a.year);

  return featuredProjects;
};
