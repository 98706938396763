/*!
 * IMPORTANT:
 * the <a> tags will remain anchor tags.
 * Using the NextJS <Link /> component will break the functionality
!*/

import React from "react";

interface INavProps {
  aboutRef: React.RefObject<HTMLDivElement>;
  experienceRef: React.RefObject<HTMLDivElement>;
  projectsRef: React.RefObject<HTMLDivElement>;
  activeSection: React.RefObject<HTMLDivElement>;
  handleNavClick: (ref: React.RefObject<HTMLDivElement>) => void;
}

const Nav: React.FC<INavProps> = ({ handleNavClick, aboutRef, experienceRef, projectsRef, activeSection }) => {
  return (
    <nav className="nav hidden lg:block">
      <ul className="mt-16 w-max">
        <li>
          <a
            id="about-nav"
            className={`group flex items-center py-3 cursor-pointer ${
              activeSection === aboutRef ? "active" : ""
            }`}
            onClick={() => handleNavClick(aboutRef)}
          >
            <span className="nav-indicator mr-4 h-px w-8 bg-slate-600 transition-all group-hover:w-16 group-hover:bg-slate-200 group-focus-visible:w-16 group-focus-visible:bg-slate-200 motion-reduce:transition-none" />
            <span className="nav-text text-xs font-bold uppercase tracking-widest text-slate-500 group-hover:text-slate-200 group-focus-visible:text-slate-200">
              About
            </span>
          </a>
        </li>
        <li>
          <a
            id="experience-nav"
            className={`group flex items-center py-3 cursor-pointer ${
              activeSection === experienceRef? "active" : ""
            }`}
            onClick={() => handleNavClick(experienceRef)}
          >
            <span className="nav-indicator mr-4 h-px w-8 bg-slate-600 transition-all group-hover:w-16 group-hover:bg-slate-200 group-focus-visible:w-16 group-focus-visible:bg-slate-200 motion-reduce:transition-none" />
            <span className="nav-text text-xs font-bold uppercase tracking-widest text-slate-500 group-hover:text-slate-200 group-focus-visible:text-slate-200">
              Experience
            </span>
          </a>
        </li>
        <li>
          <a
            id="projects-nav"
            className={`group flex items-center py-3 cursor-pointer ${
              activeSection === projectsRef ? "active" : ""
            }`}
            onClick={() => handleNavClick(projectsRef)}
          >
            <span className="nav-indicator mr-4 h-px w-8 bg-slate-600 transition-all group-hover:w-16 group-hover:bg-slate-200 group-focus-visible:w-16 group-focus-visible:bg-slate-200 motion-reduce:transition-none" />
            <span className="nav-text text-xs font-bold uppercase tracking-widest text-slate-500 group-hover:text-slate-200 group-focus-visible:text-slate-200">
              Projects
            </span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
