import { IFeaturedPosts } from "../interfaces";
import { posts } from "@/app/data/blogPostData";
import { sortPostsByDate } from "./utils";

export const getFeaturedPosts = (): IFeaturedPosts[] => {
  const sortedPosts = sortPostsByDate(posts);
  const featuredPosts = sortedPosts.slice(0, 2);

  return featuredPosts;
};
