import React from "react";
import { IExperience } from "@/app/shared/interfaces";
import Link from "next/link";
import { MdOutlineArrowOutward } from "react-icons/md";
import SKills from "@/app/shared/components/Skills/Skills";

interface IExperienceListProps {
  experience: ReadonlyArray<IExperience>;
}

const ExperienceList: React.FC<IExperienceListProps> = ({ experience }) => {
  return (
    <ol className="group/list">
      {experience.map((exp) => (
        <li className="mb-12" key={exp.id}>
          <div className="group relative grid pb-1 transition-all sm:grid-cols-8 sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
            <div className="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg" />
            <header className="z-10 mb-2 mt-1 text-xs font-semibold uppercase tracking-wide text-slate-500 sm:col-span-2">
              {`${exp.startYear} — ${exp.endYear ? exp.endYear : "PRESENT"}`}
            </header>
            <div className="z-10 sm:col-span-6">
              <h3 className="font-medium leading-snug text-slate-200">
                <div>
                  <Link
                    className={`inline-flex items-baseline font-medium leading-tight text-slate-200 hover:text-blue-300 focus-visible:text-blue-300  group/link text-base ${
                      !exp.link && "pointer-events-none cursor-auto"
                    }`}
                    href={exp.link || ""}
                    target="_blank"
                    rel="noreferrer"
                    aria-label={`${exp.jobTitle} at ${exp.companyName}`}
                  >
                    <span className="absolute -inset-x-4 -inset-y-2.5 hidden rounded md:-inset-x-6 md:-inset-y-4 lg:block" />
                    <span>{exp.jobTitle}{exp.companyName && (<>&nbsp;·&nbsp;</>)}</span>
                    {exp.companyName}
                    {exp.link && (
                      <span className="inline-block">
                        <MdOutlineArrowOutward className="inline-block h-4 w-4 shrink-0 transition-transform group-hover/link:-translate-y-1 group-hover/link:translate-x-1 group-focus-visible/link:-translate-y-1 group-focus-visible/link:translate-x-1 motion-reduce:transition-none ml-1 translate-y-px" />
                      </span>
                    )}
                  </Link>
                </div>
              </h3>
              <p className="mt-2 text-sm leading-normal">{exp.description}</p>
              <SKills skills={exp.skills} />
            </div>
          </div>
        </li>
      ))}
    </ol>
  );
};

export default ExperienceList;
